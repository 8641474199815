import { ref, Ref, readonly, toRefs, reactive } from 'vue'

import { AxiosPromise } from 'axios'

/* eslint-disable @typescript-eslint/no-explicit-any */

type ResponseType = {
  nextPageToken?: string;
}

type RequestType = {
    readonly pageSize?: number
    pageToken?: string
    readonly filter?: string
    readonly orderBy?: string
} | undefined

type ListMethod<U extends ResponseType, R> = (request: R, options: any) => AxiosPromise<U>

function useListModel<U extends ResponseType, R extends RequestType, T>(method: ListMethod<U, R>): {
    items: Ref<T[]>;
    fetchItems: (request: R, options: any) => Promise<void>;
    readonly end: Ref<boolean>;
    readonly error: Ref<boolean>;
    clearError: () => void;
    readonly loading: Ref<boolean>;
    nextPage: () => Promise<void>;
} {
  const state = reactive({
    items: [] as T[],
    end: false,
    error: false,
    loading: false,
    request: {} as R,
    options: undefined as any,
  })

  function getArrayElem(payload: ResponseType): T[] {
    for (const [, v] of Object.entries(payload)) {
      if (Array.isArray(v)) {
        return v.map(i => i)
      }
    }

    return new Array<T>(0)
  }

  async function fetchItems(request?: R, options?: any) {
    state.loading = true
    state.request = ref<R>(request || {} as R).value
    state.options = options
    state.items = reactive([])

    try {
      const payload = await method(state.request as R, options)
      const newItems = getArrayElem(payload.data)
      state.items = reactive(newItems)
      if (payload.data.nextPageToken) {
        // just set this
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.request!.pageToken = payload.data.nextPageToken
      }
    } catch (e) {
      console.error(e)
      state.error = true
    }

    state.loading = false
  }

  async function nextPage() {
    if (state.end) {
      return
    }

    state.loading = true
    try {
      const payload = await method(state.request as R, state.options)
      const newItems = getArrayElem(payload.data)

      if (payload.data.nextPageToken) {
        // just set this
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.request!.pageToken = payload.data.nextPageToken
      }

      if (newItems.length === 0) {
        state.end = true
        return
      }

      state.items.push(...reactive(newItems))
    } catch (e) {
      console.error(e)
      state.error = true
    }

    state.loading = false
  }

  function clearError() { state.error = false }

  const { items, end, error, loading } = toRefs(state)

  return {
    items: items as Ref<T[]>,
    end: readonly(end),
    error: readonly(error),
    loading: readonly(loading),
    clearError,
    fetchItems,
    nextPage,
  }
}

export default useListModel
